.card {
    width: 100%;
    height: 100%;
    padding-bottom: 15px;
    max-width: 400px;
    overflow: hidden;
    border-radius: 20px;
    background-color: white;
    position: relative;
    //   display: -webkit-box; 
    //   display: -moz-box;
    //   display: -ms-flexbox;
    //   display: -webkit-flex; 
    display: flex;
    flex-direction: column;
    justify-content: space-between;


    .cardTop {
        -webkit-flex-basis: 155px;
        flex-basis: 155px;
        // flex-shrink:1;
        background-color: rgba(0, 0, 0, .2);
        background-position: center;
        background-size: cover;
    }

    .cardProfile {
        flex-basis: 75px;

        // flex-shrink:1;
        .profileImage {
            overflow: hidden;
            border-radius: 100%;
            position: absolute;
            left: calc(50% - 75px);
            top: calc(25% - 75px);
            border: 4px solid white;
            width: 150px;
            height: 150px;
            background-size: cover;
            background-position: center;
        }
    }

}

.contentContainer {
    height: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: space-between;

    h3 {
        margin: 0;
        font-size: 24px;
        font-weight: bold;
        line-height: 10px;
    }

    p {
        padding-left: 5px;
        padding-right: 5px;
        font-size: 16px;
        line-height: 20px;
    }

    button {
        background: #FE6526;
        color: #FFFFFF;
        width: 300px;
        // padding-left: 30px;
        // padding-right: 30px;
        height: 48px;
        border-radius: 999px;
        font-weight: 800;
        border: none;
        letter-spacing: 1.2px;
        font-size: 16px;
        cursor: pointer;
    }
}

.tagsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    gap:7px;
    padding:0px 5px;
}

.tagCloud {
    display: inline-block;
    color: #25282B;
    padding: 5px 10px;
    border-radius: 25px;
    // background-color: #A0A4A8;
    // margin-top: 8px;
    // margin-left: 5px;
    // margin-right: 5px;
    font-size: 15px;
    text-align: center;
    line-height: 20px;
    border: 1px solid #A0A4A8;
}

.react-multiple-carousel__arrow {
    position: absolute;
    outline: 0;
    transition: all .5s;
    border-radius: 35px;
    z-index: 1000;
    border: 0;
    background: teal;
    min-width: 43px;
    min-height: 43px;
    opacity: 1;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &.react-multiple-carousel__arrow--right {
        right: 20px;
    }

    &.react-multiple-carousel__arrow--left {
        left: 20px;
    }

    svg {
        width: 1.5em;
        height: 1.5em;
        color: white;
    }
}

.carousel-container {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

}

.carousel-item-spacing {
    display: flex;
    justify-content: center;
    padding: 10px;
    /* Add space inside each item */
    box-sizing: border-box;
    /* Ensure padding doesn’t break layout */
}

@media (max-width:768px) {
    .card {
        width: 335px;
    }

    .carousel-container {
        justify-content: flex-start;


    }
}