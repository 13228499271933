@use '../assets/css-vars/breakpoints' as breakpoints;


.hero-slider {
    margin: 0;
    padding: 0;
    width: 100%;

    .container-fluid {
        margin: 0;
        padding: 0;
    }
}

.how_works {
    margin-top: 30px;

    h3 {
        font-size: 17px;
    }

    .img-fluid {
        border-radius: 15px;
    }

    p {
        margin-top: 20px;
    }
}

.how_works_header img {
    height: 60px;
}

.banner_mweb {
    display: none;
}

.personal_assistance {
    h3 {
        margin-top: 30px;
    }

    img {
        padding: 15px 0;
        border-radius: 10px;
        max-width: 100%;
    }
}

.brand_logo_section img {
    max-width: 100%;
    padding: 20px;
}

.flex-two-column {
    display: flex;
    justify-content: center;
    align-items: center;

    .left-section,
    .right-section {
        flex: 1 1 0px;
        padding: 30px;
    }

    .left-section {
        border-right: solid 3px #fff;
    }
}

.instruction_tip {
    padding: 20px;
    display: none;

    h3 {
        display: block;
        text-align: center;
        padding: 15px;
        text-transform: none;
        font-family: 'Lora';
        font-weight: normal;
        font-size: 17px;

    }

    .upload_header {
        display: flex;
        align-items: center;

        img {
            max-width: 100%;
        }

        p {
            padding: 30px;
            text-align: center;
        }
    }
}

.step_container_scan {
    width: 100%;
    height: 100%;

    p {
        display: flex;
        padding: 5px 20px;
        font-size: 12px;
        column-gap: 15px;
        align-items: center;
        color: #000;
        font-weight: bold;
        height: 50px;
        line-height: 18px;
        margin: 0;
        border-radius: 15px 15px 0 0;

        span {
            flex-shrink: 0;
            width: 30px;
            height: 30px;
            background: rgba(150, 207, 205, 0.20);
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #000;
            border: solid 1px #000;
        }
    }

    .step_container_desp {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 12px;
        padding: 0;
        flex-direction: column;

        p {
            margin-top: 20px;
            padding: 10px 25px;
        }

        img {
            max-width: 100%;
            max-height: 200px;
            object-fit: cover;
            border: solid 1px #000;
            border-radius: 10px;
        }
    }
}

.app_download {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    img {
        max-width: 180px;
    }
}

.lets_design {
    margin-left: auto;
    margin-right: auto;
    font-size: 20px;
    width: 90%;
    text-align: center;
    margin-top: 20px;
    font-weight: bold;
}

@media screen and (max-width: breakpoints.$md) {
    .instruction_container {
        padding: 0;
        box-shadow: none;
    }

    .instruction_footer {
        box-shadow: none;
        padding: 20px 0;
    }

    .instruction_tip {
        padding: 0
    }

    .flex-two-column {
        flex-direction: column;
    }

    .flex-two-column .left-section {
        border-right: none;
        border-bottom: solid 2px #fff;
    }

    .flex-two-column .left-section,
    .flex-two-column .right-section {
        padding-left: 0;
        padding-right: 0;
    }

    .banner_mweb {
        display: block;
    }

    .banner_desktop {
        display: none;
    }
}

.video_caption_mweb {
    display: none;
}

@media screen and (max-width: breakpoints.$dsk) {

    .video_caption_mweb {
        display: block;
    }

    .video_caption_dskt {
        display: none;
    }

    .instruction_tip {
        display: block;
    }

    .room_selector,
    .scanquiz_selector {
        display: none !important;
    }
}

.content_section {
    padding: 15px 50px;
}

.caption {
    font-family: 'Lora';
    color: #fff;
    font-size: 50px;
    margin-bottom: 30px;
}

.play_video_text {
    font-family: 'Nunito';
    color: #fff;
    display: flex;
    column-gap: 10px;
    align-items: center;
}


.content_left_section {
    width: 60%;
    padding-bottom: 50px;
}

.content_right_section {
    width: 40%;

    img {
        max-width: 100%;
    }
}

.content_left_section {
    display: flex;
    flex-direction: column;
    justify-content: end;
}

.img-max-100 {
    max-width: 100%;
}

.img-rounded {
    border-radius: 10px;
}


@media screen and (max-width: breakpoints.$sm) {
    .content_section {
        padding: 15px 0;
    }
}

@media screen and (max-width: breakpoints.$lg) {
    .content_left_section {
        justify-content: center;
        padding-bottom: 0;
    }
}

@media screen and (max-width: breakpoints.$md) {
    .content_right_section {
        width: 100%;
        text-align: center;
    }

    .content_left_section {
        position: absolute;
        width: 60%;
        right: 30px;
        bottom: 30px;

        .caption {
            font-size: 20px;
            margin-bottom: 15px;
        }

        .play_video_text {
            font-size: 14px;
            line-height: normal;

            img {
                max-width: 35px;
            }
        }
    }
}

.discover_container,
.design_perfectfit_container,
.viewinroom_container {
    align-items: center;
}

@media screen and (max-width: breakpoints.$md) {
    .discover_container {
        flex-direction: column-reverse;
        margin: 0;
        width: 100%;
        text-align: center;

        h3 {
            font-size: 20px;
        }
    }

    .design_perfectfit_container {
        text-align: center;
        width: calc(100% + 48px);
        margin-left: -24px;

        h3 {
            font-size: 20px;
        }
    }

    .viewinroom_container {
        flex-direction: column-reverse;
        margin: 0;
        width: 100%;
        text-align: center;

        h3 {
            font-size: 20px;
        }
    }
}

// new landing page css
//landing page 4th section
.landingPageFourthsectionDescription {
    font-family: Nunito;
    font-size: 16px;
    font-weight: 600;
    line-height: 22.5px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;
    margin: 0;
}

.landingPageFourthsectionButton {
    width: 205.31px;
    height: 52.08px;
    gap: 0px;
    border-radius: 999px;
    border: 3px solid #FE6526;
    font-family: Nunito;
    font-size: 16.5px;
    font-weight: 700;
    line-height: 22.51px;
    letter-spacing: 0.02em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    background: #FFFFFF;
    color: #FE6526;
    margin-bottom: 5px;
    cursor: pointer;
}

.landingPageFourthsectionTitle {
    font-family: Lora;
    font-size: 24px;
    font-style: italic;
    font-weight: 400;
    line-height: 27px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

}

.landingPageFourthsectionImage {
    width: 187px;
    height: 187px;

}

.landingPageFourthsection {
    display: flex;
    justify-content: center;
    align-items: stretch;
    row-gap: 30px;
    column-gap: 30px;
    text-align: center;
    flex-wrap: nowrap;
    overflow-x: auto;
    /* Enable horizontal scrolling */
    scrollbar-width: none;
    /* Hide scrollbar for Firefox */
    -ms-overflow-style: none;
    padding-left: 10px;
    padding-right: 10px;
}

.landingPageFourthsectionHeaderContainer {
    background-color: #DE9444;
    padding-top: 10px;
    padding-bottom: 10px;
}

.landingPageFourthsectionHeader {
    font-family: Lora;
    font-size: 31.5px;
    font-weight: 400;
    line-height: 33.75px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #FFFFFF;
    margin: 0;
    margin-top: 10px;
    margin-bottom: 10px;

    span {
        font-family: Lora;
        font-size: 26.25px;
        font-style: italic;
        font-weight: 400;
        line-height: 33.75px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #FFFFFF;

    }
}


//landing page third section
.featuresBackgroundContainer {
    background: #96CFCD4D;
    padding: 30px 20px;
    border-radius: 33px;
}

.featuresContainer {
    // flex-wrap: wrap;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 30px;
    row-gap: 30px;
}

.featuresHeading {
    margin-top: 0;
    margin-bottom: 20px;
    font-family: Lora;
    font-size: 31.5px;
    font-weight: 400;
    line-height: 33.75px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

}

.featuresHeading em {
    font-style: italic;
    // font-weight: bold;
}

.featureItem {
    display: flex;
    justify-content: center;
}

.featureCircle {
    width: 184.5px;
    height: 184.5px;
    background: linear-gradient(180deg, #0D908E 67%, #DFF1F0 100%);
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
    font-family: "Nunito", sans-serif;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.featureCircle h3 {
    font-family: Lora;
    font-size: 21px;
    font-style: italic;
    font-weight: 400;
    line-height: 33.75px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin: 0
}

.featureCircle p {
    font-family: Nunito;
    font-size: 18px;
    font-style: italic;
    font-weight: 500;
    line-height: 22.5px;

    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #FFFFFF;
    margin: 0;
}


//lading page second section
.landingPageSecondSectionLine {
    border: 2px solid #0D908E
}

.landingPageSecondHeader {
    font-family: Lora;
    font-size: 31.5px;
    font-weight: 400;
    line-height: 33.75px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;

}

.landingPageSecondHeaderSpan {
    font-family: Lora;
    font-size: 24px;
    font-style: italic;
    font-weight: 400;
    line-height: 33.75px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

}

.landingPageSecondSection {
    display: flex;
    justify-content: center;
    column-gap: 30px;
    row-gap: 30px;
    text-align: center;
    align-items: center;

    h3 {
        font-family: Nunito;
        font-size: 27px;
        font-weight: 700;
        line-height: 33.75px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #0D908E;
        margin: 10px 0;
    }

    p {
        font-family: Lora;
        font-size: 21px;
        font-style: italic;
        font-weight: 400;
        line-height: 33.75px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #000000;
        margin: 5px 0;
    }

    span {
        font-family: Lora;
        font-size: 21px;
        font-style: italic;
        font-weight: 600;
        line-height: 33.75px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;

    }
}

.landingPageSecondSectionFlexChild {
    width: 301px;
    /* 25% width for 4 items per row */
    box-sizing: border-box;
}

//lading page 6th testmonial section
.landingPageTestimonialSection {
    display: flex;
    align-items: stretch;
    justify-content: space-around;
    text-align: center;
    column-gap: 50px;
    flex-wrap: nowrap;
    /* Prevent wrapping */
    overflow-x: auto;
    /* Enable horizontal scrolling */
    scrollbar-width: none;
    /* Hide scrollbar for Firefox */
    -ms-overflow-style: none;
    padding-left: 10px;
    padding-right: 10px;

}

.landingPageTestimonialSectionContainer {
    // row-gap: 30px;
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    // text-align: left;
    // align-items: center;

}

.landingPageTestimonialProfileImage {
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 20px;
}

.landingPageTestimonial {
    font-family: Lora;
    font-size: 21px;
    font-style: italic;
    font-weight: 400;
    line-height: 33.75px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

}

.landingPageTestimonialCustomerName {
    font-family: Nunito;
    font-size: 18px;
    font-weight: 400;
    line-height: 33.75px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

}

//5th section
.landingPageFifthSectionContainer {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    text-align: center;
    border-radius: 33px;
    background-color: #F98F451A;
    padding-top: 30px;
    padding-bottom: 50px;

    p {
        font-family: Lora;
        font-size: 24px;
        // font-style: italic;
        font-weight: 400;
        line-height: 27px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        margin-top: 0;
    }
}

.plusIcon {
    font-size: 50px;
    color: #FE6526;
    padding-top: 100px;
    // margin-left: 20px;
}

//7th section
.landingPageSeventhSection {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;
    text-align: center;
}

.landingPageSeventhSectionHeaderContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    column-gap: 30px;
    padding-left: 10px;
    padding-right: 10px;
}

.landingPageSeventhSectionHeader {
    font-family: Lora;
    font-size: 31.5px;
    font-weight: 400;
    line-height: 33.75px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

}

.landingPageSeventhSectionGetStartedButton {
    width: 205.31px;
    height: 52.08px;
    border-radius: 999px;
    border: 3px solid #FFFFFF;
    background: #FE6526;
    font-family: Nunito;
    font-size: 16.5px;
    font-weight: 700;
    line-height: 22.51px;
    letter-spacing: 0.015em;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
}


.landingPageSeventhLeftSection {
    background-color: #96CFCD4D;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
}

.landingPageSeventhLeftSectionText {
    font-family: Lora;
    font-size: 30px;
    font-weight: 500;
    line-height: 35.75px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #2F2440;


}

.findADesignerButton {
    width: 381.75px;
    height: 51.75px;
    border-radius: 999px;
    color: #FE6526;
    background: #FFFFFF;
    border: 2px solid #FE6526;
    font-family: Nunito;
    font-size: 18px;
    font-weight: 700;
    line-height: 24.56px;
    letter-spacing: 0.075em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    cursor: pointer;


}

.landingPageSeventhRightSection {
    background-color: #96CFCD4D;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;

}

.landingPageSeventhRightSectionText {
    font-family: Lora;
    font-size: 30px;
    font-weight: 500;
    line-height: 35.75px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #2F2440;

}

@media (max-width: 1024px) {

    //second section
    .landingPageSecondSectionFlexChild {
        width: calc(40% - 30px);
        /* Take 50% of the row width, accounting for gap */
    }

    .landingPageSecondSection {
        flex-wrap: wrap;

    }

    //third section
    .featuresContainer {
        flex-wrap: wrap;
    }

    //fouth section
    .landingPageFourthsection {
        justify-content: space-between;
    }

    //5th section

    .fifthSectionImageOne {
        width: 138px;
        height: 108px;
    }

    //seventh section
    .landingPageSeventhLeftSection {

        img {
            width: 265px;
            height: 238px;
        }
    }

    .landingPageSeventhLeftSectionText {
        font-size: 25px;
        line-height: 32.75px;

        i {
            font-size: 10px;
        }
    }

    .landingPageSeventhRightSection {
        img {
            width: 80px;
            height: 80px;
        }

    }

    .landingPageSeventhRightSectionSofaImage {
        width: 110px !important;
    }

    .landingPageSeventhRightSectionText {
        font-size: 25px;
        line-height: 32.75px;

    }

    .findADesignerButton {
        width: 230px;
        height: 41px;
        font-size: 16px;
        line-height: 20px;
    }

}

@media (max-width:600px) {

    //lading page second section
    .landingPageSecondHeader {
        font-size: 28.5px;
        line-height: 33.75px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .landingPageSecondHeaderSpan {
        font-size: 22.5px;
        line-height: 33.75px;
    }

    .landingPageSecondSection {
        flex-wrap: nowrap;

        flex-direction: column;
        align-items: center;

        h3 {
            font-size: 31.5px;
            color: #000000;
        }
    }

    .landingPageSecondSectionLine {
        width: 95%;
        margin: auto;
        border: 1px solid #0D908E
    }

    .landingPageSecondSectionFlexChild {
        width: 100%;
    }

    //landing page third section
    .featuresBackgroundContainer {
        padding-top: 20px;
    }

    .featuresContainer {
        flex-wrap: nowrap;
        flex-direction: column;
        row-gap: 50px;
    }

    .featureItem {
        width: 100%;
    }

    //4th section
    .landingPageFourthsection {
        column-gap: 10px;
    }

    .landingPageFourthsectionFlexChild {
        flex-shrink: 0;
    }

    //5th section
    .landingPageFifthSectionContainer {
        padding: 20px;
        padding-bottom: 40px;
        flex-direction: column;
        justify-content: center;

        p {
            font-size: 20px;
            line-height: 30px;
        }
    }

    .plusIcon {
        padding: 0;
    }

    //lading page 6th testmonial section
    .landingPageTestimonialSection {
        justify-content: flex-start;
        column-gap: 10px;
        width: 100%;
    }

    //7 th section
    .landingPageSeventhSection {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 30px;
        text-align: center;
    }

    .landingPageSeventhSectionHeaderContainer {
        flex-direction: column;
        row-gap: 20px;
    }

    .landingPageSeventhSectionHeader {
        text-align: center;
        margin-bottom: 0;
    }

    .landingPageSeventhLeftSection {
        padding-left: 10px;
        padding-right: 10px;
        width: 100%;
    }

    .landingPageSeventhRightSection {
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;

    }
}